import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useToast } from "./use-toast";
import { useIndexedDB } from "./use-indexed";
import { useMotoristaStore } from "@/store/motorista-store";
import { MotoristaFormData, motoristaSchema } from "@/types/motorista";

interface UseMotoristaProps {
  databaseName: string;
  onSave: (data: MotoristaFormData) => void;
}

export const useMotoristaForm = ({ databaseName, onSave }: UseMotoristaProps) => {
  const { toast } = useToast();
  const { isLoading, setIsLoading } =
    useMotoristaStore();
  const { add } = useIndexedDB("motoristas");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<MotoristaFormData>({
    resolver: zodResolver(motoristaSchema),
    defaultValues: {
      database_name: databaseName,
      placa: '',
      nome: '',
    },
  });

  const onSubmit = async (data: MotoristaFormData) => {
    try {

      setIsLoading(true);

      const res = await add({
        ...data,
        placa: data.placa.replace(/[^A-Z0-9]/g, "").trim()
      });

      toast({
        title: "Sucesso",
        description: "Seus dados foram salvos!",
        variant: "success",
      });

      reset();
      onSave(res);

    } catch (error: any) {
      toast({
        title: "Erro",
        description: error.message || "Erro ao registrar ocorrência",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    setValue,
    control,
    errors,
    isLoading,
    onSubmit,
  };
};
