import { CameraControlsBottom } from "@/components/camera/CameraControlBottom";
import { CameraControlsTop } from "@/components/camera/CameraControlTop";
import { CameraPreview } from "@/components/camera/CameraPreview";

const Foto: React.FC = () => {

  return (
    <div className="relative w-full h-dvh grid grid-cols-1 gap-6 place-items-center overflow-hidden backdrop-blur-none bg-black">
      <CameraControlsTop />
      <CameraPreview />
      <CameraControlsBottom />
    </div>
  );
};

export default Foto;