import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ClockIcon } from "lucide-react";
import { useId, useState, useEffect } from "react";
import { pt } from 'date-fns/locale';

export default function CalendarPage() {
  const id = useId();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [time, setTime] = useState<string>("12:00:00");

  const isPastDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  useEffect(() => {
    // Definir o horário para a hora atual
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    
    const currentTime = `${hours}:${minutes}:${seconds}`;
    setTime(currentTime);
  }, []);

  return (
    <div>
      <div className="rounded-md border">
        <Calendar
          mode="single"
          className="p-2"
          selected={date}
          onSelect={setDate}
          disabled={isPastDate}
          locale={pt}
        />
        <div className="border-t p-3">
          <div className="flex flex-col gap-2 w-full">
            <Label htmlFor={id} className="text-xs">
              Horário
            </Label>
            <div className="relative grow">
              <Input
                id={id}
                type="time"
                step="1"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="peer appearance-none ps-9 [&::-webkit-calendar-picker-indicator]:hidden [&::-webkit-calendar-picker-indicator]:appearance-none"
              />
              <div className="text-gray-600/80 pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 peer-disabled:opacity-50">
                <ClockIcon size={16} aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
