import { DBOperations, initDBService } from "@/services/indexed-db.service";
import { useMemo } from "react";


export interface IndexedDBProps {
  name: string;
  version: number;
  objectStoresMeta: ObjectStoreMeta[];
}

export interface ObjectStoreMeta {
  store: string;
  storeConfig: { keyPath: string; autoIncrement: boolean; [key: string]: any };
  storeSchema: ObjectStoreSchema[];
}

export interface ObjectStoreSchema {
  name: string;
  keypath: string | string[];
  options: { unique: boolean; [key: string]: any };
}

export interface useIndexedDB {
  dbName: string;
  version: number;
  objectStore: string;
}

const indexeddbConfiguration: { version: number | null; name: string | null } = {
  version: null,
  name: null,
};

export function initDB({ name, version, objectStoresMeta }: IndexedDBProps) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      indexeddbConfiguration.name = name;
      indexeddbConfiguration.version = version;
      await initDBService({ name, version, objectStoresMeta });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export function useIndexedDB(objectStore: string): {
  add: <T = any>(value: T, key?: any) => Promise<T>;
  getByID: <T = any>(id: number | string) => Promise<T>;
  getAll: <T = any>() => Promise<T[]>;
  getFirst: <T = any>() => Promise<T | undefined>;
  update: <T = any>(value: T, key?: IDBValidKey) => Promise<any>;
  deleteRecord: (key: IDBValidKey) => Promise<any>;
  openCursor: (
    cursorCallback: (cursor: IDBCursorWithValue) => void,
    keyRange?: IDBKeyRange,
  ) => Promise<void>;
  getByIndex: (indexName: string, key: IDBValidKey) => Promise<any>;
  getByMultipleIndexes: (indexes: { indexName: string; value: IDBValidKey | IDBKeyRange }[], options?: { orderBy?: string; direction?: 'asc' | 'desc' }) => Promise<any>;
  countByMultipleIndexes: (indexes: { indexName: string; value: IDBValidKey | IDBKeyRange }[]) => Promise<number>;
  clear: () => Promise<any>;
} {
  const db = useMemo(() => {
    if (!indexeddbConfiguration.name || !indexeddbConfiguration.version) {
      return null;
    }
    return DBOperations(objectStore);
  }, [indexeddbConfiguration, objectStore]);

  if (!db) {
    throw new Error("Please, initialize the DB before the use.");
  }

  return db;
}