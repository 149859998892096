import { z } from "zod";

export const motoristaSchema = z.object({
  id: z.number().optional(),
  placa: z
    .string()
    .min(1, "Placa é obrigatória")
    .regex(
      /^[A-Z]{3}-\d{4}$|^[A-Z]{3}-\d[A-Z]\d{2}$/,
      "Formato inválido de placa"
    ),
  nome: z.string().min(1, "Nome é obrigatório"),
  database_name: z.string(),
});

export type MotoristaFormData = z.infer<typeof motoristaSchema>;
