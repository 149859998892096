import { create } from 'zustand';

export interface Photo {
  imageData: ArrayBuffer;
  imageType: string;
  imageName: string;
}

interface CameraState {
  isActive: boolean;
  photoData: Photo | null;
  videoRef: React.RefObject<HTMLVideoElement> | null;
  fileInputRef: React.RefObject<HTMLInputElement> | null;
  setFileInputRef: (ref: React.RefObject<HTMLInputElement> | null) => void;
  setIsActive: (isActive: boolean) => void;
  setPhotoData: (photoData: Photo | null) => void;
  setVideoRef: (ref: React.RefObject<HTMLVideoElement> | null) => void;
}

export const useCameraStore = create<CameraState>((set) => ({
  isActive: false,
  photoData: null,
  videoRef: null,
  fileInputRef: null,
  setFileInputRef: (ref: React.RefObject<HTMLInputElement> | null) => set({ fileInputRef: ref }),
  setIsActive: (isActive: boolean) => set({ isActive }),
  setPhotoData: (photoData: Photo | null) => set({ photoData }),
  setVideoRef: (ref: React.RefObject<HTMLVideoElement> | null) => set({ videoRef: ref }),
}));