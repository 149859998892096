import { BarcodeScannerControlBottom } from "@/components/scanner/BarcodeScannerControlBottom";
import { BarcodeScannerControlTop } from "@/components/scanner/BarcodeScannerControlTop";
import { BarcodeScannerPreview } from "@/components/scanner/BarcodeScannerPreview";

const Scanner: React.FC = () => {

  return (
    <div className="relative w-full h-dvh grid grid-cols-1 gap-6 place-items-center overflow-hidden backdrop-blur-none">
      <BarcodeScannerControlTop />
      <BarcodeScannerPreview />
      <BarcodeScannerControlBottom />
    </div>
  );
};

export default Scanner;