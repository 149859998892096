import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useScannerStore } from "@/store/scanner-store";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { z } from "zod"
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { clearNumber } from "@/lib/utils";

const schema = z.object({
  numero_documento: z.string()
  .min(1, "Número é obrigatório")
  .max(9, "Número deve ter no máximo 9 dígitos")
  .regex(/^\d+$/, "Apenas números inteiros são permitidos")
});

const Numero: React.FC = () => {

  const params = useParams();
  const navigate = useNavigate();
  const databaseName = params.databaseName as string;

  const { setNumeroDocumento } = useScannerStore();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      numero_documento: '',
    }
  });

  const onSubmit = (data: { numero_documento: string }) => {
    setNumeroDocumento(clearNumber(data.numero_documento));
    navigate(`/${databaseName}/foto`);
  };

  return (
    <div className="min-h-screen bg-background font-[family-name:var(--font-geist-sans)]">
      {/* Top App Bar */}
      <header className="bg-blue-500 text-white px-4 py-2 flex items-center">
        <Button onClick={() => navigate(-1)} size="icon" className="mr-2">
          <ArrowLeft className="h-6 w-6" />
        </Button>
      </header>

      <div className="p-4">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="space-y-2">
            <Label htmlFor="scanned-code">Número NF-e</Label>
            <Input maxLength={9} {...register("numero_documento")} type="number" id="scanned-code" placeholder="Digite o número da NF-e" />
            {errors.numero_documento && <span className="text-red-500 text-sm">{errors.numero_documento.message}</span>}
          </div>
          <Button type="submit" variant="default" size="lg" className="w-full">Próximo <ArrowRight size={20} className="ml-2" /></Button>
        </form>
      </div>

    </div>
  );
};

export default Numero;