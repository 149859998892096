import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useToast } from "./use-toast";
import { useIndexedDB } from "./use-indexed";
import { useEffect, useState } from "react";
import { OcorrenciaFormData } from "@/types/ocorrencia";

interface UseEntregaProps {
  onSave: (data: OcorrenciaFormData) => void;
  entrega: OcorrenciaFormData | null;
}

export const entregaSchema = z.object({
  id: z.number().optional(),
  numero_documento: z.string().min(1, "Número é obrigatório"),
});

export const useEntregaForm = ({ entrega, onSave }: UseEntregaProps) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { update } = useIndexedDB("entregas");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<OcorrenciaFormData>({
    resolver: zodResolver(entregaSchema),
    defaultValues: {
      numero_documento: "",
    },
  });

  useEffect(() => {
    if (entrega) {
      setValue("numero_documento", entrega.numero_documento);
    }
  }, []);

  const onSubmit = async (data: OcorrenciaFormData) => {
    try {
      setIsLoading(true);

      await update({
        ...entrega,
        ...data,
      });

      toast({
        title: "Sucesso",
        description: "Seus dados foram salvos!",
        variant: "success",
      });

      reset();
      onSave({
        ...entrega,
        ...data,
      });
    } catch (error: any) {
      toast({
        title: "Erro",
        description: error.message || "Erro ao salvar entrega",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    setValue,
    control,
    errors,
    isLoading,
    onSubmit,
  };
};
