import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ArrowRight } from "lucide-react"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import React from 'react';
import { useNavigate } from "react-router-dom"

const schema = z.object({
  codigo: z.string().min(1, "Código é obrigatório"),
});

const Selecao: React.FC = () => {

  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      codigo: '',
    }
  });

  const onSubmit = (data: {codigo: string}) => {
    navigate(`/${data.codigo}`);
  };

  return (
    <div className="min-h-screen bg-background font-[family-name:var(--font-geist-sans)]">
      <main className="p-4">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <Label htmlFor="scanned-code">Código da empresa</Label>
            <Input {...register("codigo")} type="text" id="scanned-code" placeholder="Digite o código da empresa" />
            {errors.codigo && <span className="text-red-500 text-sm">{errors.codigo.message}</span>}
          </div>
          <Button type="submit" variant="default" size="lg" className="w-full">Próximo <ArrowRight size={20} className="ml-2" /></Button>
        </form>
      </main>
    </div>
  );
};

export default Selecao;