import { Card, CardContent } from "@/components/ui/card";
import { OcorrenciaFormData } from "@/types/ocorrencia";
import { Calendar, Clock } from "lucide-react";
import { StatusIcon } from "./StatusIcon";
import { OcorrenciaIdBadge } from "./OcorrenciaIdBadge";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
// import { Badge } from "../ui/badge";

export function EntregasCards({
  numero_documento,
  ocorrencia_id,
  dt_ocorrencia,
  hora_ocorrencia,
  status,
  id,
}: OcorrenciaFormData) {
  const navigate = useNavigate();
  const { databaseName } = useParams();

  const formatDate = (dateString: string, format: string) => {
    return dayjs(dateString).format(format);
  };

  return (
    <Card
      onClick={() => navigate(`/${databaseName}/entregas/visualizar/${id}`)}
      className="w-full bg-white cursor-pointer"
    >
      <CardContent className="flex justify-between p-4">
        <div>
          <div className="flex items-start">
            <div className="max-w-[200px]">
              <h2 className="text-lg font-semibold text-gray-900 truncate">
                NF-e {numero_documento}
              </h2>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex gap-4 py-2 items-center">
              <div className="flex items-center gap-1">
                <div>
                  <Calendar size={14} className="text-gray-500" />
                </div>
                <div>
                  <p className="text-[13px] text-gray-500">
                    {formatDate(dt_ocorrencia, "DD/MM/YYYY")}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div>
                  <Clock size={14} className="text-gray-500" />
                </div>
                <div>
                  <p className="text-[13px] text-gray-500 ">
                    {formatDate(hora_ocorrencia, "HH:mm:ss")}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <OcorrenciaIdBadge ocorrencia_id={ocorrencia_id} />
              {/* {error && (
                <Badge
                  variant="outline"
                  className="bg-red-50 text-red-700 border-red-200"
                >
                  Erro
                </Badge>
              )} */}
            </div>
          </div>
        </div>
        <div>
          <StatusIcon status={status} />
        </div>
      </CardContent>
    </Card>
  );
}
