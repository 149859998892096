import { useScanner } from "@/hooks/use-scanner";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";


export function BarcodeScannerControlTop() {

  const { databaseName } = useParams<{ databaseName: string }>();
  const { stopScanner } = useScanner();
  const navigate = useNavigate();

  const handleBack = () => {
    stopScanner();
    navigate(`/${databaseName}`);
  }

  return (
    <div
      className="absolute flex top-4 text-sm w-full px-4 py-8 z-40 font-[family-name:var(--font-geist-sans)]"
    >
      <button
        className="absolute top-4 left-4 bg-white p-2 rounded-full shadow-md z-40"
        onClick={handleBack}
      >
        <ArrowLeft className="text-black" />
      </button>
    </div>
  );
}