import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader2 } from "lucide-react";
import { useEntregaForm } from "@/hooks/use-entrega-form";
import { OcorrenciaFormData } from "@/types/ocorrencia";

interface RegistrationFormProps {
  onSave: (data: OcorrenciaFormData) => void;
  entrega: OcorrenciaFormData | null;
}

export default function EntregaForm({
  onSave,
  entrega,
}: RegistrationFormProps) {
  const { register, handleSubmit, errors, isLoading, onSubmit } =
    useEntregaForm({
      entrega,
      onSave,
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-4">
      <div className="space-y-1">
        <Label htmlFor="nome">Número NF-e</Label>
        <Input
          {...register("numero_documento")}
          type="number"
          id="numero_documento"
          placeholder="Digite o número da NF-e"
        />
        {errors.numero_documento && (
          <span className="text-red-500">
            {errors.numero_documento.message}
          </span>
        )}
      </div>
      <Button
        disabled={isLoading}
        type="submit"
        variant="default"
        size="lg"
        className="w-full"
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Salvando...
          </>
        ) : (
          "Salvar"
        )}
      </Button>
    </form>
  );
}
