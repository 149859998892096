import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { Toaster } from "./components/ui/toaster.tsx";

import { IndexedDBProvider } from "./providers/IndexedDBProvider.tsx";
import { PwaProvider } from "./providers/PwaProvider.tsx";
import { H } from "highlight.run";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (
  import.meta.env.VITE_ENV === "production" ||
  import.meta.env.VITE_ENV === "test"
) {
  H.init("4d7y2mjd", {
    serviceName: "frontend-app",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <IndexedDBProvider>
        <PwaProvider>
          <Toaster />
          <App />
        </PwaProvider>
      </IndexedDBProvider>
    </QueryClientProvider>
  </StrictMode>
);
