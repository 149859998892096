import { useNavigate, useParams } from "react-router-dom";

export function BarcodeScannerControlBottom() {

  const { databaseName } = useParams<{ databaseName: string }>();

  const navigate = useNavigate();

  return (
    <div className="absolute bottom-24 z-40">
      <button
        className="text-sm w-auto bg-blue-500 text-white px-6 py-4 rounded-full font-[family-name:var(--font-geist-sans)]"
        onClick={() => navigate(`/${databaseName}/numero`)}
      >
        Digitar número da nota fiscal
      </button>
    </div>
  );
}