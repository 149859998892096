import { Camera as CameraIcon, Image, SendHorizonal } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useCamera } from "@/hooks/use-camera";
import { useNavigate, useParams } from "react-router-dom";
import { useCameraStore } from "@/store/camera-store";
import { useEffect, useRef } from "react";

export function CameraControlsBottom() {
  const navigate = useNavigate();
  const params = useParams();
  const databaseName = params.databaseName as string;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { photoData, takePhoto, handleFileUpload, triggerFileInput } = useCamera();
  const { setPhotoData, setFileInputRef } = useCameraStore();

  const handleSendPhoto = () => {
    setPhotoData(null);
    navigate(`/${databaseName}/ocorrencia`);
  };

  useEffect(() => {
    setFileInputRef(fileInputRef);
  }, []);

  return (
    <div className="absolute flex justify-center bottom-24 text-sm w-full px-4 py-8 z-40 font-[family-name:var(--font-geist-sans)]">
      {!photoData ? (
        <>
          <Button
            onClick={triggerFileInput}
            size="sm"
            variant="white"
            className="p-4 rounded-full h-14 w-14 shadow-md left-4 absolute top-1/2 -translate-y-1/2 bg-black/40"
          >
            <Image size={26} color="white" />
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            className="hidden"
            onChange={handleFileUpload}
          />
          <Button
            onClick={takePhoto}
            size="lg"
            variant="white"
            className="p-6 rounded-full h-auto shadow-md"
          >
            <CameraIcon size={30} />
          </Button>
        </>
      ) : (
        <Button
          onClick={handleSendPhoto}
          size="lg"
          variant="green"
          className="p-6 rounded-full h-auto shadow-md"
        >
          <SendHorizonal color="white" size={30} />
        </Button>
      )}
    </div>
  );
}
