import { useState, useEffect } from "react";
import { useToast } from "./use-toast";

type PermissionState = "granted" | "prompt" | "denied" | "unknown";

export function useCameraPermission() {
  const permissions = {
    granted: "A câmera está ativa ou as permissões foram concedidas.",
    prompt: "Você precisa permitir o acesso à câmera.",
    denied: "O acesso à câmera foi negado. Verifique as permissões no navegador.",
    unknown: "Verificando permissões...",
  }
  const [permissionState, setPermissionState] = useState<PermissionState>("unknown");
  const [permissionStateDescription, setPermissionStateDescription] = useState('granted');
  const { toast } = useToast();

  const checkPermission = async () => {
    try {
      if (!("permissions" in navigator)) {
        console.warn("API de permissões não suportada neste navegador.");
        setPermissionState("unknown");
        setPermissionStateDescription(permissions.unknown);
        return;
      }

      const permissionStatus = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });

      setPermissionState(permissionStatus.state as PermissionState);
      setPermissionStateDescription(permissions[permissionStatus.state as PermissionState]);

      // Escuta mudanças no estado da permissão
      permissionStatus.onchange = () => {
        setPermissionState(permissionStatus.state as PermissionState);
        setPermissionStateDescription(permissions[permissionStatus.state as PermissionState]);
      };
    } catch (error) {
      console.error("Erro ao verificar permissões da câmera:", error);
      setPermissionState("unknown");
      setPermissionStateDescription(permissions.unknown);
    }
  };

  const requestCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("Acesso à câmera concedido:", stream);
      toast({
        title: "Sucesso",
        description: "Permissão concedida com sucesso. Você já pode começar a scanear códigos de barras.",
        variant: "success",
      });
      // location.reload();
      setPermissionState("granted");
      setPermissionStateDescription(permissions.granted);
    } catch (error) {
      console.error("Acesso à câmera negado:", error);
      setPermissionState("denied");
      setPermissionStateDescription(permissions.denied);
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return {
    permissionState,
    permissionStateDescription,
    requestCameraAccess,
    checkPermission,
  };
}