import { cn } from "@/lib/utils";
import { eOcorrenciaStatus } from "@/types/ocorrencia";
import { CircleCheck, CloudAlert, CloudOff } from "lucide-react";

interface StatusIconProps {
  status: eOcorrenciaStatus;
  showText?: boolean;
  variant?: "bg" | "default";
}

const statusOptions = {
  [eOcorrenciaStatus.SINCRONIZADO]: {
    text: "Sincronizado",
    color: "text-green-700",
    bg: "bg-green-50",
    icon: <CircleCheck size={20} className="text-green-700" />,
  },
  [eOcorrenciaStatus.NAO_SINCRONIZADO]: {
    text: "Não sincronizado",
    color: "text-orange-700",
    bg: "bg-orange-50",
    icon: <CloudOff size={20} className="text-orange-700" />,
  },
  [eOcorrenciaStatus.ERRO_SINCRONIZACAO]: {
    text: "Erro de sincronização",
    color: "text-red-700",
    bg: "bg-red-50",
    icon: <CloudAlert size={20} className="text-red-700" />,
  },
};

export const StatusIcon: React.FC<StatusIconProps> = ({
  status,
  showText = false,
  variant = "bg",
}) => {
  return (
    <div
      className={cn(
        "font-medium leading-none tracking-tight flex flex-row items-center gap-4",
        variant == "bg" && `${statusOptions[status].bg} p-2 rounded-full`
      )}
    >
      <div>{statusOptions[status].icon}</div>
      {showText && <div className={cn("font-semibold", statusOptions[status].color)}>{statusOptions[status].text}</div>}
    </div>
  );
};
