import { isPhone } from "./utils";

// interface MediaConstraints {
//   audio: boolean;
//   video: {
//     height: { ideal: number };
//     width: { ideal: number };
//     deviceId: string;
//     aspectRatio?: number;
//     facingMode: string;
//     resizeMode: boolean;
//     focusMode: string;
//     focusDistance: number;
//     exposureMode: string;
//     zoom: number;
//     frameRate: { ideal: number; max: number };
//   };
// }

async function getCameraIdWithFlash() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput' && !device.label.toLowerCase().includes('wide'));
  for (const device of videoDevices) {
    const constraints = {
      video: {
        deviceId: device.deviceId,
        facingMode: "environment",
      },
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      if ("torch" in capabilities && capabilities.torch) {
        stream.getTracks().forEach((track) => track.stop());
        return device.deviceId;
      }
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error(`Error with device ${device.deviceId}:`, error);
    }
  }
  return null;
}

async function getAndSetCameraIdWithFlash() {
  let cameraId = localStorage.getItem("cameraIdWithFlash");
  if (!cameraId) {
    cameraId = await getCameraIdWithFlash();
    if (cameraId) {
      localStorage.setItem("cameraIdWithFlash", cameraId);
    }
  }
  return cameraId;
}

export async function getMediaStream(): Promise<any> {
  const cameraId = isPhone() ? await getAndSetCameraIdWithFlash() : "";
  const baseSettings = isPhone()
    ? {
        height: { ideal: 1080 },
        width: { ideal: 1920 },
      }
    : {
        height: { ideal: 480, max: 1080 },
        width: { ideal: 640,  max: 1920 },
      };

  return navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      ...baseSettings,
      deviceId: cameraId || "",
      aspectRatio: undefined,
      facingMode: "environment",
      frameRate: { ideal: 15, max: 30 },
    },
  });
}

export function convertToGrayscale(imageData: ImageData) {
  const data = imageData.data;
  for (let i = 0; i < data.length; i += 4) {
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
    data[i] = avg; // Red
    data[i + 1] = avg; // Green
    data[i + 2] = avg; // Blue
  }
  return imageData;
}
