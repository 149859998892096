import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import InputMask from "@mona-health/react-input-mask";
import { useMotoristaForm } from "@/hooks/use-motorista-form";
import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { MotoristaFormData } from "@/types/motorista";
import { Controller } from "react-hook-form";

interface RegistrationFormProps {
  onSave: (data: MotoristaFormData) => void;
}

interface MaskedState {
  value: string;
}

export default function MotoristaForm({ onSave }: RegistrationFormProps) {

  const { databaseName } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    isLoading,
    control,
    onSubmit,
  } = useMotoristaForm({
    databaseName: databaseName as string,
    onSave,
  });

  function beforeMaskedStateChange({ nextState }: { nextState: MaskedState }) {
    let { value } = nextState;
    if (value) {
      value = value.toUpperCase();
    }

    return {
      ...nextState,
      value,
    };
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-4">
      <div className="space-y-1">
        <Label htmlFor="placa">Placa</Label>
        <Controller
          name="placa"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <div>
              <InputMask
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                {...field}
                mask="aaa-9*99"
                maskPlaceholder={null}
                placeholder="Digite a placa do seu veículo"
                beforeMaskedStateChange={beforeMaskedStateChange}
              />
              {fieldState.error && (
                <span className="text-red-500">{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className="space-y-1">
        <Label htmlFor="nome">Nome</Label>
        <Input
          {...register("nome")}
          id="nome"
          placeholder="Digite seu nome"
        />
        {errors.nome && <span className="text-red-500">{errors.nome.message}</span>}
      </div>
      <Button
        disabled={isLoading}
        type="submit"
        variant="default"
        size="lg"
        className="w-full"
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Salvando...
          </>
        ) : (
          "Salvar"
        )}
      </Button>
    </form>
  );
}
