import { useEffect } from 'react'
import { useDatabaseName } from './use-database-name';

const defaultConfig = {
  display: 'standalone',
  background_color: '#ffffff',
  theme_color: '#000000',
  icons: [
    {
      src: '/icon-192x192.png',
      sizes: '192x192',
      type: 'image/png'
    },
    {
      src: '/icon-512x512.png',
      sizes: '512x512',
      type: 'image/png'
    }
  ]
}

export function useManifest(config = {}) {

  const databaseName = useDatabaseName();

  useEffect(() => {

    const origin = window.location.origin;

    if (!origin || !databaseName) return

    const fullStartUrl = new URL(databaseName, origin).href;

    // Gerar manifest combinando configurações padrão com as personalizadas
    const manifest = {
      ...defaultConfig,
      ...config,
      icons: [
        {
          src: origin + "/icons/icon-96x96.png",
          sizes: "96x96",
          type: "image/png"
        },
        {
          src: origin + "/icons/icon-180x180.png",
          sizes: "180x180",
          type: "image/png",
          purpose: "maskable"
        },
        {
          src: origin + "/icons/icon-192x192.png",
          sizes: "192x192",
          type: "image/png"
        },
        {
          src: origin + "/icons/icon-512x512.png",
          sizes: "512x512",
          type: "image/png"
        }
      ],
      scope: origin,
      start_url: fullStartUrl,
    }

    // Gerenciar a tag do manifest
    let link = document.querySelector('link[rel="manifest"]') as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'manifest'
      document.head.appendChild(link)
    }

    // Criar e configurar o Blob
    const manifestBlob = new Blob(
      [JSON.stringify(manifest)],
      { type: 'application/manifest+json' }
    )
    const manifestURL = URL.createObjectURL(manifestBlob);
    link.setAttribute('href', manifestURL);

    // Cleanup
    return () => URL.revokeObjectURL(manifestURL)
  }, [config, databaseName])
}