import { useCamera } from "@/hooks/use-camera";
import { useEffect, useRef, useState } from "react";

export function CameraPreview() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const { setVideoRef, photoData, startCamera, stopCamera } = useCamera();

  useEffect(() => {
    setVideoRef(videoRef);
    startCamera();

    return () => {
      stopCamera();
    };
  }, []);

  useEffect(() => {
    if (photoData && photoData.imageData) {
      const blob = new Blob([photoData.imageData], {
        type: photoData.imageType,
      });
      const url = URL.createObjectURL(blob);
      setImageURL(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [photoData]);

  if (photoData && imageURL) {
    return (
      // <img
      //   src={imageURL}
      //   alt="Captured photo"
      //   className="w-screen h-screen object-cover relative z-20"
      // />
      <img
        src={imageURL}
        alt="Captured photo"
        className="h-dvh object-cover relative z-20"
      />
    );
  }

  return (
    <>
      <video
        title="Camera photo"
        ref={videoRef}
        autoPlay
        playsInline
        className="w-full h-full object-cover relative z-20"
      />
    </>
  );
}
