import { create } from "zustand";
import { Photo } from "./camera-store";
import {
  eOcorrenciaStatus,
  OcorrenciaFormData,
} from "@/types/ocorrencia";

interface FilterState {
  indexName: string;
  value: IDBValidKey | IDBKeyRange;
}

interface OcorrenciaState {
  ocorrencia: OcorrenciaFormData | null;
  ocorrencias: OcorrenciaFormData[];
  ocorrenciaPhoto: Photo | null;
  isLoading: boolean;
  filters: FilterState[];
  getSelectedStatus: (status: eOcorrenciaStatus | 'all') => boolean;
  setFilter: (filter: FilterState | FilterState[]) => void;
  setIsLoading: (loading: boolean) => void;
  setOcorrenciaPhoto: (ocorrenciaPhoto: Photo | null) => void;
  resetOcorrenciaPhoto: () => void;
  addOcorrencia: (ocorrencia: OcorrenciaFormData) => void;
  editOcorrencia: (id: number, updatedOcorrencia: OcorrenciaFormData) => void;
  deleteOcorrencia: (id: number) => void;
  setOcorrencia: (ocorrencia: OcorrenciaFormData | null) => void;
  setOcorrencias: (ocorrencias: OcorrenciaFormData[]) => void;
  unsyncedOcorrencias: () => OcorrenciaFormData[];
}

export const useOcorrenciaStore = create<OcorrenciaState>((set, get) => ({
  ocorrencia: null,
  ocorrencias: [],
  ocorrenciaPhoto: null,
  isLoading: false,
  filters: [],
  setFilter: (newFilter: FilterState | FilterState[]) =>
    set((state) => {
      const filters = Array.isArray(newFilter) ? newFilter : [newFilter];
      let updatedFilters = [...state.filters];

      filters.forEach((filter) => {
        const existingFilterIndex = updatedFilters.findIndex(
          (f) => f.indexName === filter.indexName
        );
        if (filter.value === "all") {
          if (existingFilterIndex !== -1) {
            updatedFilters = updatedFilters.filter(
              (_, index) => index !== existingFilterIndex
            );
          }
          return;
        }
        if (existingFilterIndex !== -1) {
          if (updatedFilters[existingFilterIndex].value !== filter.value) {
            updatedFilters[existingFilterIndex] = filter;
          }
          return;
        }
        updatedFilters.push(filter);
      });
      return { filters: updatedFilters };
    }),
  getSelectedStatus: (status: eOcorrenciaStatus | "all") => {
    const state = get();
    const statusFilter = state.filters.find((f) => f.indexName === "status");

    if (!statusFilter) {
      return status === "all";
    }

    return statusFilter.value === status;
  },
  setIsLoading: (loading) => set({ isLoading: loading }),
  setOcorrenciaPhoto: (ocorrenciaPhoto) => set({ ocorrenciaPhoto }),
  resetOcorrenciaPhoto: () => set({ ocorrenciaPhoto: null }),
  addOcorrencia: (ocorrencia) =>
    set((state) => ({ ocorrencias: [...state.ocorrencias, ocorrencia] })),
  editOcorrencia: (id, updatedOcorrencia) =>
    set((state) => ({
      ocorrencias: state.ocorrencias.map((ocorrencia) =>
        ocorrencia.id === id ? updatedOcorrencia : ocorrencia
      ),
    })),
  deleteOcorrencia: (id) =>
    set((state) => ({
      ocorrencias: state.ocorrencias.filter(
        (ocorrencia) => ocorrencia.id !== id
      ),
    })),
  setOcorrencia: (ocorrencia: OcorrenciaFormData | null) => set({ ocorrencia }),
  setOcorrencias: (ocorrencias) => set({ ocorrencias }),
  unsyncedOcorrencias: () => get().ocorrencias.filter(ocorrencia => ocorrencia.status !== eOcorrenciaStatus.SINCRONIZADO)
}));
