import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "./components/layouts/default";
import Selecao from "./pages/selecao/Page";
import Home from "./pages/home/Page";
import Scanner from "./pages/scanner/Page";
import Entregas from "./pages/entregas/Page";
import Numero from "./pages/numero/Page";
import Foto from "./pages/foto/Page";
import Ocorrencia from "./pages/ocorrencia/Page";

import BottomNavLayout from "./components/layouts/bottomNav";
import { useManifest } from "./hooks/use-manifest";
import EntregasVisualizar from "./pages/entregas/visualizar/Page";
import CalendarPage from "./pages/calendar/Page";

function App() {
  // const { getAll } = useIndexedDB("entregas");

  useManifest({
    name: "Zorte Entrega",
    shortName: "Zorte",
    description: "App de baixa de entrega para motoristas",
    backgroundColor: "#ffffff",
    themeColor: "#d94245",
  });


  // startPeriodicSync(1, getAll);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/selecao" replace />} />
        <Route path="/selecao" element={<Selecao />} />
        <Route path="/calendar" element={<CalendarPage />} />

        <Route element={<BottomNavLayout />}>
          <Route index path="/:databaseName" element={<Home />} />
          <Route path="/:databaseName/entregas" element={<Entregas />} />
        </Route>

        <Route path="/:databaseName" element={<DefaultLayout />}>
          <Route path="scanner" element={<Scanner />} />
          <Route path="numero" element={<Numero />} />
          <Route path="foto" element={<Foto />} />
          <Route path="ocorrencia" element={<Ocorrencia />} />
          <Route
            path="entregas/visualizar/:id"
            element={<EntregasVisualizar />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
