import React, { useEffect, useState } from "react";
import { Download, ScanBarcode } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BottomSheet from "@/components/bottom-sheet/BottomSheets";
import MotoristaForm from "@/components/motorista-form/MotoristaForm";
import { useIndexedDB } from "@/hooks/use-indexed";
import { Button } from "@/components/ui/button";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useCameraPermission } from "@/hooks/use-camera-permission";
import { useToast } from "@/hooks/use-toast";
import { usePwaInstall } from "@/providers/PwaProvider";
import { useRegisterSW } from "virtual:pwa-register/react";
import { Badge } from "@/components/ui/badge";

const APP_VERSION = import.meta.env.VITE_VERSION;

const Home: React.FC = () => {
  const location = useLocation(); // Pega a rota atual

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      r && setInterval(() => {
        r.update();
      }, 15 * 60 * 1000); // A cada 15 minutos
    },
    onRegisterError(error) {
      console.error("Erro ao registrar Service Worker:", error);
    },
  });

  useEffect(() => {
    // Verifica se está na home antes de tratar a atualização
    if (needRefresh) {
      updateServiceWorker(true); 
    }
  }, [location.pathname, needRefresh, updateServiceWorker]);

  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { databaseName } = useParams<{ databaseName: string }>();
  const { isInstallable, installApp } = usePwaInstall();
  const { getFirst } = useIndexedDB("motoristas");
  const { toast } = useToast();
  const { permissionState, requestCameraAccess, permissionStateDescription } =
    useCameraPermission();
  const navigate = useNavigate();
  const onClose = () => {
    // The BottomSheet component will handle preventing closure if fields are empty
  };
  const onSave = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const motorista = await getFirst();
      if (!motorista) {
        setIsOpen(true);
      }
    };
    setIsMounted(true);
    fetchData();
  }, []);

  const handleScan = async () => {
    if (["prompt", "denied"].includes(permissionState)) {
      toast({
        title: "Atenção",
        description: "Você precisa conceder permissão para acessar a câmera",
        variant: "warning",
      });
      requestCameraAccess();
      return;
    }
    navigate(`/${databaseName}/scanner`);
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-8 pb-20 sm:p-20 font-[family-name:var(--font-geist-sans)]">
      {isInstallable && (
        <Button
          onClick={installApp}
          className="group bg-gray-100 border absolute top-8"
          variant="secondary"
        >
          <Download
            className="-ms-1 me-2"
            size={16}
            strokeWidth={2}
            aria-hidden="true"
          />
          Instale o app agora
        </Button>
      )}

      <Badge className="absolute top-2" variant={'default'}>{APP_VERSION}</Badge>

      {["prompt", "denied"].includes(permissionState) && (
        <div className="p-8 absolute top-[-20px]">
          <Alert variant="destructive" className="w-full">
            <AlertTitle>Atenção</AlertTitle>
            <AlertDescription>
              {permissionStateDescription}
              <br />
              <Button
                className="mt-2"
                size={"sm"}
                variant={"destructive"}
                onClick={requestCameraAccess}
              >
                Conceder Permissão
              </Button>
            </AlertDescription>
          </Alert>
        </div>
      )}

      <button
        onClick={handleScan}
        className="bg-white border-2 border-gray-700 px-14 py-14 rounded-3xl flex flex-col items-center justify-center gap-4 transition-transform hover:scale-105 active:scale-95"
      >
        <ScanBarcode size={70} className="text-gray-700" />
        <span className="text-lg font-medium text-gray-700">
          Ler código de barras da NF-e
        </span>
      </button>

      {isMounted && (
        <BottomSheet
          title="Cadastre-se"
          description="Insira a placa do seu veículo e o seu nome."
          isOpen={isOpen}
          onClose={onClose}
        >
          <MotoristaForm onSave={onSave} />
        </BottomSheet>
      )}
    </div>
  );
};

export default Home;
