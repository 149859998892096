import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import type * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  components: userComponents,
  ...props
}: React.ComponentProps<typeof DayPicker>) {
  const defaultClassNames = {
    months: "relative flex flex-col sm:flex-row gap-4",
    month: "w-full",
    month_caption:
      "relative mx-10 mb-1 flex h-9 items-center justify-center z-20",
    caption_label: "text-sm font-medium",
    nav: "absolute top-0 flex w-full justify-between z-10",
    button_previous: cn(
      buttonVariants({ variant: "white" }),
      "size-12 text-gray-600/80 hover:text-black hover:bg-gray-200 p-0"
    ),
    button_next: cn(
      buttonVariants({ variant: "white" }),
      "size-12 text-gray-600/80 hover:text-black hover:bg-gray-200 p-0"
    ),
    weekday: "size-12 p-0 text-xs font-medium text-gray-600/80",
    day_button:
      "relative flex size-12 items-center justify-center whitespace-nowrap rounded-md p-0 text-black group-[[data-selected=true]:not(.range-middle)]:[transition-property:color,background-color,border-radius,box-shadow] group-[[data-selected=true]:not(.range-middle)]:duration-150 group-data-[disabled=true]:pointer-events-none focus-visible:z-10 group-[&:not([data-selected='true'])]:hover:bg-gray-200 group-data-[selected=true]:bg-black group-[&:not([data-selected='true'])]:hover:text-black group-data-[selected=true]:text-white group-data-[disabled=true]:text-black/30 group-data-[disabled=true]:line-through group-data-[outside=true]:text-black/30 group-data-[selected=true]:group-data-[outside=true]:text-white outline-none focus-visible:ring-ring/50 focus-visible:ring-[3px] group-[.range-start:not(.range-end)]:rounded-e-none group-[.range-end:not(.range-start)]:rounded-s-none group-[.range-middle]:rounded-none group-[.range-middle]:group-data-[selected=true]:bg-accent group-[.range-middle]:group-data-[selected=true]:text-black",
    day: "group size-12 px-0 py-px text-sm",
    range_start: "range-start",
    range_end: "range-end",
    range_middle: "range-middle",
    today:
      "*:after:pointer-events-none *:after:absolute *:after:bottom-1 *:after:start-1/2 *:after:z-10 *:after:size-[3px] *:after:-translate-x-1/2 *:after:rounded-full *:after:bg-black [&[data-selected=true]:not(.range-middle)>*]:after:bg-background [&[data-disabled=true]>*]:after:bg-foreground/30 *:after:transition-colors",
    outside:
      "text-gray-600 data-[selected=true]:bg-accent/50 data-[selected=true]:text-gray-600",
    hidden: "invisible",
    week_number: "size-12 p-0 text-xs font-medium text-gray-600/80",
  };

  const mergedClassNames: typeof defaultClassNames = Object.keys(
    defaultClassNames
  ).reduce(
    (acc, key) => ({
      ...acc,
      [key]: classNames?.[key as keyof typeof classNames]
        ? cn(
            defaultClassNames[key as keyof typeof defaultClassNames],
            classNames[key as keyof typeof classNames]
          )
        : defaultClassNames[key as keyof typeof defaultClassNames],
    }),
    {} as typeof defaultClassNames
  );

  const defaultComponents = {
    Chevron: (props: any) => {
      if (props.orientation === "left") {
        return <ChevronLeftIcon size={16} {...props} aria-hidden="true" />;
      }
      return <ChevronRightIcon size={16} {...props} aria-hidden="true" />;
    },
  };

  const mergedComponents = {
    ...defaultComponents,
    ...userComponents,
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("w-fit", className, 'custom-calendar')}
      classNames={mergedClassNames}
      components={mergedComponents}
      {...props}
    />
  );
}

export { Calendar };
