import { DataChave } from '@/lib/utils';
import { create } from 'zustand';

interface ScannerState {
  chave: string | null;
  dataChave: DataChave | null;
  numeroDocumento: string | null;
  stream: MediaStream | null;
  videoRef: React.RefObject<HTMLVideoElement> | null;
  canvasRef: React.RefObject<HTMLCanvasElement> | null;
  audioRef: React.RefObject<HTMLAudioElement> | null;
  isScanning: boolean;
  setIsScanning: (isScanning: boolean) => void;        
  setCanvasRef: (canvasRef: React.RefObject<HTMLCanvasElement> | null) => void;
  setAudioRef: (audioRef: React.RefObject<HTMLAudioElement> | null) => void;
  setStream: (stream: MediaStream | null) => void;
  setVideoRef: (videoRef: React.RefObject<HTMLVideoElement> | null) => void;
  setNumeroDocumento: (numeroDocumento: string | null) => void;
  setChave: (chave: string | null) => void;
  setDataChave: (data: DataChave | null) => void;
}

export const useScannerStore = create<ScannerState>((set) => ({
  chave: null,
  dataChave: null,
  numeroDocumento: null,
  stream: null,
  videoRef: null,
  canvasRef: null,
  audioRef: null,
  isScanning: false,
  setIsScanning: (isScanning) => set({ isScanning }),
  setCanvasRef: (canvasRef) => set({ canvasRef }),
  setAudioRef: (audioRef) => set({ audioRef }),
  setStream: (stream) => set({ stream }),
  setVideoRef: (videoRef: React.RefObject<HTMLVideoElement> | null) => set({ videoRef }),
  setNumeroDocumento: (numeroDocumento) => set({ numeroDocumento }),
  setChave: (chave) => set({ chave }),
  setDataChave: (dataChave) => set({ dataChave }),
}));


// const videoRef = useRef<HTMLVideoElement>(null);
// const canvasRef = useRef<HTMLCanvasElement>(null);
// const audioRef = useRef<HTMLAudioElement>(null);



// interface CameraState {
//   videoRef: React.RefObject<HTMLVideoElement> | null;
//   photo: Photo | null;
//   stream: MediaStream | null;
//   cameraStarted: boolean;
//   setCameraStarted: (started: boolean) => void;
//   setPhoto: (photo: Photo | null) => void;
//   setStream: (stream: MediaStream | null) => void;
//   setVideoRef: (videoRef: React.RefObject<HTMLVideoElement> | null) => void;
//   reset: () => void;
// }

// export const useCameraStore = create<CameraState>((set) => ({
//   videoRef: null,
//   photo: null,
//   stream: null,
//   cameraStarted: false,
//   setCameraStarted: (started) => set({ cameraStarted: started }),
//   setPhoto: (photo) => set({ photo }),
//   setStream: (stream) => set({ stream }),
//   reset: () => set({ photo: null, stream: null }),
//   setVideoRef: (ref) => set({ videoRef: ref }),
// }));