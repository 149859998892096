import { getMediaStream } from "@/lib/camera";
import { useCameraStore } from "@/store/camera-store";
import { useOcorrenciaStore } from "@/store/ocorrencia-store";
import { useCallback, useRef } from "react";

export const useCamera = () => {
  const {
    photoData,
    videoRef,
    setPhotoData,
    setVideoRef,
    fileInputRef,
  } = useCameraStore();
  const { setOcorrenciaPhoto } = useOcorrenciaStore();
  const streamRef = useRef<MediaStream | null>(null);

  const startCamera = useCallback(async () => {
    try {
      const stream = await getMediaStream();

      if (!stream) {
        throw new Error("Could not access any camera");
      }

      streamRef.current = stream;

      const currentVideoRef = useCameraStore.getState().videoRef;
      if (!currentVideoRef?.current) {
        throw new Error("Video reference not found");
      }

      currentVideoRef.current.srcObject = stream;

      // const currentVideoRef = useCameraStore.getState().videoRef;
      // if (currentVideoRef?.current) {
      //   currentVideoRef.current.srcObject = stream;
      //   setPhotoData(null);
      // }
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  }, [setPhotoData]);

  const stopCamera = useCallback(() => {
    // const currentVideoRef = useCameraStore.getState().videoRef;
    // if (currentVideoRef?.current?.srcObject) {
    //   const tracks = (
    //     currentVideoRef.current.srcObject as MediaStream
    //   ).getTracks();
    //   tracks.forEach((track) => track.stop());
    //   currentVideoRef.current.srcObject = null;
    // }
    if (videoRef?.current) {
      videoRef.current.srcObject = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      if (videoRef?.current?.srcObject) {
        videoRef.current.srcObject = null;
      }
      streamRef.current = null;
    }
  }, []);

  const resetCamera = useCallback(() => {
    setPhotoData(null);
    startCamera();
  }, [setPhotoData, startCamera]);

  const takePhoto = useCallback(() => {
    const currentVideoRef = useCameraStore.getState().videoRef;
    if (currentVideoRef?.current) {
      const canvas = document.createElement("canvas");
      canvas.width = currentVideoRef.current.videoWidth;
      canvas.height = currentVideoRef.current.videoHeight;

      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(currentVideoRef.current, 0, 0);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const imageData = await blob.arrayBuffer();
            const imageType = "image/jpeg";
            const imageName = `photo-${Date.now()}.jpg`;

            const photoData = { imageData, imageType, imageName };

            setPhotoData(photoData);
            setOcorrenciaPhoto(photoData);
          }
        }, "image/jpeg");
      }
      stopCamera();
    }
  }, []);

  const downloadPhoto = useCallback(() => {
    if (photoData && photoData?.imageData) {
      const blob = new Blob([photoData.imageData], {
        type: photoData.imageType,
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = photoData.imageName || `photo-${Date.now()}.jpg`;
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  }, [photoData]);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          const result = e.target?.result;
          if (result instanceof ArrayBuffer) {
            const photoData = {
              imageData: result,
              imageType: file.type,
              imageName: file.name,
            };
            setPhotoData(photoData);
            setOcorrenciaPhoto(photoData);
          }
        };

        reader.readAsArrayBuffer(file);
      }
    },
    []
  );

  const triggerFileInput = useCallback(() => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const deletePhoto = useCallback(() => {
    setPhotoData(null);
  }, [setPhotoData]);

  return {
    videoRef,
    setVideoRef,
    photoData,
    startCamera,
    stopCamera,
    resetCamera,
    takePhoto,
    downloadPhoto,
    deletePhoto,
    handleFileUpload,
    triggerFileInput
  };
};
