import { Link, useLocation, useParams } from 'react-router-dom';
import { HomeIcon, PackageIcon } from 'lucide-react';

export function BottomNavigation() {
  const { pathname } = useLocation();
  const { databaseName } = useParams();

  // const cleanPathname = pathname.replace(`${databaseName}`, '');

  const tabs = [
    { name: "Home", href: `/${databaseName}`, icon: HomeIcon },
    { name: "Entregas", href: `/${databaseName}/entregas`, icon: PackageIcon },
  ];

  return (
    <div className="bg-white border-t border-gray-200 fixed bottom-0 w-full">
      <div className="flex justify-around">
        {tabs.map((tab) => {
          const isActive = pathname === tab.href
          return (
            <Link
              key={tab.name}
              to={tab.href}
              className={`flex-1 p-4 flex flex-col items-center ${isActive ? "text-blue-500" : "text-gray-500"}`}
            >
              <tab.icon className="w-6 h-6" />
              <span className="text-xs mt-1 font-medium">{tab.name}</span>
            </Link>
          )
        })}
      </div>
    </div>
  );
}