import { Photo } from '@/store/camera-store';
import { z } from 'zod';

export enum eOcorrenciaStatus {
  SINCRONIZADO = "SINCRONIZADO",
  NAO_SINCRONIZADO = "NAO_SINCRONIZADO",
  ERRO_SINCRONIZACAO = "ERRO_SINCRONIZACAO",
}

export enum eOcorrenciaID {
  ENTREGUE = "2",
  NAO_ENTREGUE = "47"
}

export const ocorrenciaSchema = z.object({
  id: z.number().optional(),
  numero_documento: z.string().min(1, "Número é obrigatório").max(9, "Número deve ter no máximo 9 dígitos"),
  observacoes: z.string().optional(),
  database_name: z.string(),
  ocorrencia_id: z.enum([eOcorrenciaID.ENTREGUE, eOcorrenciaID.NAO_ENTREGUE]),
  error: z.boolean().optional(),
  message: z.string().optional(),
  status: z.enum([
    eOcorrenciaStatus.SINCRONIZADO,
    eOcorrenciaStatus.NAO_SINCRONIZADO,
    eOcorrenciaStatus.ERRO_SINCRONIZACAO
  ]),
  file: z.string().optional(),
  dt_ocorrencia: z.string(),
  hora_ocorrencia: z.string(),
  chave: z.string().optional(),
});

export type OcorrenciaFormData = z.infer<typeof ocorrenciaSchema> & {
  images: Photo[];
  documento_transporte_id: number;
  tipo_documento_transporte?: "CTE" | "MDFE_CARGA_PROPRIA" | "MINUTA" | "COLETA";
  tipo_documento_viagem_transporte?: "VIAGEM_COLETA" | "VIAGEM_ENTREGA" | "VIAGEM_MDFE";
  viagem_transporte_id?: number;
  documento_ocorrencia_id?: number;
  longitude?: number | null;
  latitude?: number | null;
};

export const TipoDocumentoTransporte = {
  CTE: "CTe",
  MDFE_CARGA_PROPRIA: "MDFe",
  MINUTA: "Minuta",
  COLETA: "Coleta",
}

export const TipoDocumentoViagemTransporte = {
  VIAGEM_COLETA: "Viagem Coleta",
  VIAGEM_ENTREGA: "Viagem Entrega",
  VIAGEM_MDFE: "Viagem MDFe",
}

// numero_documento = Numero do documento fiscal, NF-e, NF de produtor rural, declaração, dutoviário, etc.
// tipo_documento_transporte = CTE, MDFE_CARGA_PROPRIA, MINUTA, COLETA
// documento_transporte_id = ID do documento de transporte, podendo ser: CTE, MDFE, MINUTA ou COLETA
// tipo_documento_viagem_transporte = VIAGEM_COLETA, VIAGEM_ENTREGA, VIAGEM_MDFE
// viagem_transporte_id = ID da viagem no qual o documento de transporte esta incluido.