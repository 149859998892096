import { create } from 'zustand';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

interface PwaStore {
  isInstallable: boolean;
  isInstalled: boolean;
  deferredPrompt: BeforeInstallPromptEvent | null;
  setDeferredPrompt: (prompt: BeforeInstallPromptEvent | null) => void;
  setIsInstallable: (value: boolean) => void;
  setIsInstalled: (value: boolean) => void;
}

export const usePwaStore = create<PwaStore>((set) => ({
  isInstallable: false,
  isInstalled: false,
  deferredPrompt: null,
  setDeferredPrompt: (prompt) => set({ deferredPrompt: prompt }),
  setIsInstallable: (value) => set({ isInstallable: value }),
  setIsInstalled: (value) => set({ isInstalled: value }),
}));