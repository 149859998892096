import { createContext, useContext, useEffect, ReactNode } from 'react';
import { usePwaStore } from '@/store/pwa-store';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

interface PwaContextType {
  isInstallable: boolean;
  isInstalled: boolean;
  installApp: () => Promise<void>;
}

const PwaContext = createContext<PwaContextType | undefined>(undefined);

const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /ipad|iphone|ipod/.test(userAgent) ||
    (userAgent.includes('mac') && 'ontouchend' in document);
};

export function PwaProvider({ children }: { children: ReactNode }) {
  const { 
    isInstallable, 
    isInstalled, 
    deferredPrompt,
    setDeferredPrompt,
    setIsInstallable,
    setIsInstalled 
  } = usePwaStore();

  useEffect(() => {
    // Never show install prompt on iOS
    if (isIOS()) {
      setIsInstallable(false);
      return;
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    const handleAppInstalled = () => {
      setIsInstalled(true);
      setIsInstallable(false);
      setDeferredPrompt(null);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const installApp = async () => {
    if (!deferredPrompt) return;

    try {
      await deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      
      if (choiceResult.outcome === 'accepted') {
        setIsInstalled(true);
      }
      
      setDeferredPrompt(null);
      setIsInstallable(false);
    } catch (error) {
      console.error('Error installing PWA:', error);
    }
  };

  return (
    <PwaContext.Provider value={{
      isInstallable,
      isInstalled,
      installApp
    }}>
      {children}
    </PwaContext.Provider>
  );
}

export function usePwaInstall() {
  const context = useContext(PwaContext);
  if (context === undefined) {
    throw new Error('usePwaInstall must be used within a PwaProvider');
  }
  return context;
}