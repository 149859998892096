import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import {
  ArrowLeft,
  CameraIcon,
  Check,
  CircleAlert,
  Loader2,
  Trash,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { useOcorrencia } from "@/hooks/use-ocorrencia-form";
import { eOcorrenciaID } from "@/types/ocorrencia";
import { useEffect, useState } from "react";
import { DateTimePicker } from "@/components/date-time-picker/DateTimePicker";
import { Controller } from "react-hook-form";

const Ocorrencia: React.FC = () => {
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const { databaseName } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    isLoading,
    ocorrenciaPhoto,
    control,
    resetOcorrenciaPhoto,
    onSubmit,
  } = useOcorrencia({
    databaseName: databaseName as string,
  });

  useEffect(() => {
    if (ocorrenciaPhoto && ocorrenciaPhoto.imageData) {
      const blob = new Blob([ocorrenciaPhoto.imageData], {
        type: ocorrenciaPhoto.imageType,
      });
      const url = URL.createObjectURL(blob);
      setImageURL(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [ocorrenciaPhoto]);

  return (
    <div className="min-h-screen bg-background font-[family-name:var(--font-geist-sans)]">
      <header className="bg-blue-500 text-white px-4 py-2 flex items-center">
        <Button onClick={() => navigate(-1)} size="icon" className="mr-2">
          <ArrowLeft className="h-6 w-6" />
        </Button>
        <h1 className="text-base font-semibold">Finalizar entrega</h1>
      </header>
      <div className="p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <Tabs
            onValueChange={(value) =>
              setValue("ocorrencia_id", value as eOcorrenciaID)
            }
            defaultValue="2"
            className="w-full"
          >
            <TabsList className="w-full bg-gray-100">
              <TabsTrigger
                value="2"
                className="flex-1 gap-2 data-[state=active]:bg-white data-[state=active]:text-green-800 data-[state=active]:shadow-sm"
              >
                <Check size={18} /> Entrega realizada
              </TabsTrigger>
              <TabsTrigger
                value="47"
                className="flex-1 gap-2 data-[state=active]:bg-white data-[state=active]:text-red-700 data-[state=active]:shadow-sm"
              >
                <CircleAlert size={18} /> Não entregue
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="space-y-2">
            <Controller
              name="dt_ocorrencia"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="scanned-code">Número NF-e</Label>
            <Input
              {...register("numero_documento")}
              id="scanned-code"
              placeholder="Digite o número da NF-e"
              className="h-14 text-base"
            />
            {errors.numero_documento && (
              <span className="text-red-500">
                {errors.numero_documento.message}
              </span>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="observacoes">Observação</Label>
            <Textarea
              {...register("observacoes")}
              id="observacoes"
              placeholder="Digite suas observações aqui"
              rows={4}
            />
          </div>
          {!ocorrenciaPhoto && (
            <div className="space-y-2 bg-gray-100 rounded-lg p-4 border border-gray-200">
              <button
                type="button"
                onClick={() => navigate(`/${databaseName}/foto`)}
                className="flex items-center gap-4 bg-white py-2 px-4 rounded-lg border border-gray-200 w-full"
              >
                <CameraIcon size={30} />
                <div className="text-base">Adicionar foto</div>
              </button>
            </div>
          )}
          {ocorrenciaPhoto && imageURL && (
            <div>
              <Label htmlFor="foto">Foto</Label>
              <div className="flex flex-col gap-4">
                <div className="bg-gray-100 rounded-lg flex p-4 justify-between border border-gray-200">
                  <a download={ocorrenciaPhoto?.imageName} href={imageURL}>
                    {ocorrenciaPhoto?.imageName}
                  </a>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      resetOcorrenciaPhoto();
                    }}
                    className="text-red-500"
                  >
                    <Trash size={20} />
                  </a>
                </div>
              </div>
            </div>
          )}
          <Button
            disabled={isLoading}
            type="submit"
            variant="default"
            size="lg"
            className="w-full"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Registrando...
              </>
            ) : (
              "Registrar"
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Ocorrencia;
