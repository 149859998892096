import { eOcorrenciaID } from "@/types/ocorrencia";
import { Badge } from "../ui/badge";

interface OcorrenciaIdBadgeProps {
  ocorrencia_id: eOcorrenciaID;
}

export const OcorrenciaIdBadge: React.FC<OcorrenciaIdBadgeProps> = ({ ocorrencia_id }) => {
  switch (ocorrencia_id) {
    case eOcorrenciaID.ENTREGUE:
      return (
        <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
          Entrega realizada
        </Badge>
      );
    case eOcorrenciaID.NAO_ENTREGUE:
      return (
        <Badge variant="outline" className="bg-red-50 text-red-700 border-red-200">
          Entrega não realizada
        </Badge>
      );
    default:
      return null;
  }
};
