import { ObjectStoreMeta, ObjectStoreSchema } from "@/hooks/use-indexed";
import { openDB, IDBPDatabase } from "idb";

export type Key =
  | string
  | number
  | Date
  | ArrayBufferView
  | ArrayBuffer
  | IDBKeyRange;
export interface IndexDetails {
  indexName: string;
  order: string;
}

let db: IDBPDatabase | null = null;
let dbConfig: { name: string; version: number; objectStoresMeta: ObjectStoreMeta[] } | null = null;

// Função para abrir ou reabrir o banco de dados
async function ensureDBConnection() {
  if (!dbConfig) {
    throw new Error("Database configuration not provided. Call initDBService first.");
  }

  // Check if db is null or try to detect if connection is closed
  if (!db) {
    try {
      db = await openDB(dbConfig.name, dbConfig.version, {
        upgrade(db) {
          if (!dbConfig) {
            throw new Error("Database configuration not available during upgrade");
          }
          dbConfig.objectStoresMeta.forEach((storeSchema: ObjectStoreMeta) => {
            if (!db.objectStoreNames.contains(storeSchema.store)) {
              const objectStore = db.createObjectStore(
                storeSchema.store,
                storeSchema.storeConfig
              );
              storeSchema.storeSchema.forEach((schema: ObjectStoreSchema) => {
                objectStore.createIndex(schema.name, schema.keypath, schema.options);
              });
            }
          });
        },
        blocked() {
          console.warn("Database connection blocked by another version.");
        },
        blocking() {
          console.warn("Current connection is blocking an upgrade.");
          db?.close(); // Fecha a conexão atual para permitir a atualização
        },
        terminated() {
          console.warn("Database connection unexpectedly terminated.");
          db = null; // Reseta para forçar a reabertura na próxima tentativa
        },
      });
    } catch (error) {
      console.error("Error opening database:", error);
      throw error;
    }
  }
  return db;
}

// Inicialização do serviço
export async function initDBService({
  name,
  version,
  objectStoresMeta,
}: {
  name: string;
  version: number;
  objectStoresMeta: ObjectStoreMeta[];
}) {
  dbConfig = { name, version, objectStoresMeta };
  await ensureDBConnection();
  console.log("Database initialized successfully");
}

// Função auxiliar para executar operações com retentativas
async function withRetry<T>(operation: () => Promise<T>, maxAttempts = 3): Promise<T> {
  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      await ensureDBConnection(); // Garante que o db está aberto
      return await operation();
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.message.includes("The database connection is closing")
      ) {
        console.warn(`Attempt ${attempt} failed: Database connection closed. Retrying...`);
        db = null; // Força a reabertura na próxima tentativa
        if (attempt === maxAttempts) {
          throw new Error("Failed to execute operation after multiple attempts.");
        }
        await new Promise((resolve) => setTimeout(resolve, 500)); // Pequeno atraso
      } else {
        throw error; // Outro tipo de erro, não retentamos
      }
    }
  }
  throw new Error("Unexpected error in retry logic.");
}

export function DBOperations(currentStore: string) {
  const getAll = <T>() =>
    withRetry<T[]>(() => db!.getAll(currentStore));

  const add = <T>(value: T, key?: any) =>
    withRetry<T>(() =>
      db!.add(currentStore, value, key).then((id) => ({ id, ...value } as T))
    );

  const getByID = <T>(id: IDBValidKey) =>
    withRetry<T>(() => db!.get(currentStore, id));

  const update = <T>(value: T, key?: IDBValidKey) =>
    withRetry<IDBValidKey>(() => db!.put(currentStore, value, key));

  const deleteRecord = (key: IDBValidKey) =>
    withRetry<void>(() => db!.delete(currentStore, key));

  const clear = () =>
    withRetry<void>(() => db!.clear(currentStore));

  const openCursor = (
    callback: (cursor: IDBCursorWithValue) => void,
    query?: IDBKeyRange
  ) =>
    withRetry<void>(() => {
      const request = db!.transaction(currentStore).store.openCursor(query);
      return new Promise<void>((resolve, reject) => {
        request.then(
          function cursorIterate(cursor) {
            if (cursor) {
              callback(cursor as unknown as IDBCursorWithValue);
              cursor.continue();
            } else {
              resolve();
            }
          },
          (error) => reject(error)
        );
      });
    });

  const getFirst = <T>() =>
    withRetry<T | undefined>(() => {
      const request = db!.transaction(currentStore).store.openCursor();
      return new Promise<T | undefined>((resolve, reject) => {
        request.then(
          (cursor) => resolve(cursor ? (cursor.value as T) : undefined),
          (error) => reject(error)
        );
      });
    });

  const getByIndex = <T>(indexName: string, key: IDBValidKey | IDBKeyRange) =>
    withRetry<T[]>(() => db!.getAllFromIndex(currentStore, indexName, key));

  const getByMultipleIndexes = <T>(
    indexes: { indexName: string; value: IDBValidKey | IDBKeyRange }[],
    options?: { orderBy?: string; direction?: "asc" | "desc" }
  ) =>
    withRetry<T[]>(() => {
      const transaction = db!.transaction(currentStore, "readonly");
      const store = transaction.store;

      if (indexes.length === 0) {
        return store.getAll().then((data) => {
          const sortedData = options?.orderBy
            ? (data as T[]).sort((a: any, b: any) =>
                options.direction === "desc"
                  ? b[options.orderBy!] > a[options.orderBy!]
                    ? 1
                    : -1
                  : a[options.orderBy!] > b[options.orderBy!]
                  ? 1
                  : -1
              )
            : data;
          return sortedData as T[];
        });
      }

      return store
        .index(indexes[0].indexName)
        .getAll(indexes[0].value)
        .then((initialData) => {
          let results = initialData as T[];
          for (let i = 1; i < indexes.length; i++) {
            const { indexName, value } = indexes[i];
            results = results.filter((item: any) => item[indexName] === value);
          }
          if (options?.orderBy) {
            results.sort((a: any, b: any) =>
              options.direction === "desc"
                ? b[options.orderBy!] > a[options.orderBy!]
                  ? 1
                  : -1
                : a[options.orderBy!] > b[options.orderBy!]
                ? 1
                : -1
            );
          }
          return results;
        });
    });

  const countByMultipleIndexes = (
    indexes: { indexName: string; value: IDBValidKey | IDBKeyRange }[]
  ) =>
    withRetry<number>(() => {
      const transaction = db!.transaction(currentStore, "readonly");
      const store = transaction.store;

      if (indexes.length === 0) {
        return store.count();
      }

      return store
        .index(indexes[0].indexName)
        .getAll(indexes[0].value)
        .then((initialData) => {
          let results = initialData;
          for (let i = 1; i < indexes.length; i++) {
            const { indexName, value } = indexes[i];
            results = results.filter((item: any) => item[indexName] === value);
          }
          return results.length;
        });
    });

  return {
    add,
    getByID,
    getAll,
    getFirst,
    update,
    deleteRecord,
    clear,
    openCursor,
    getByIndex,
    getByMultipleIndexes,
    countByMultipleIndexes,
  };
}

export enum DBMode {
  readonly = "readonly",
  readwrite = "readwrite",
}

export async function closeDB() {
  if (db) {
    db.close();
    console.log("Database connection closed");
    // We keep the db variable to simulate the closed state
    // Not setting it to null, so operations will try to use the closed connection
  }
}