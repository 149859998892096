import { useScanner } from "@/hooks/use-scanner";
import { useEffect, useRef } from "react";

export function BarcodeScannerPreview() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { audioRef, setVideoRef, startScanner, stopScanner } = useScanner();

  useEffect(() => {

    setVideoRef(videoRef);
    startScanner();

    return () => {
      stopScanner();
    };
  }, []);

  return (
    <>
      <video
        title="Barcode Scanner"
        ref={videoRef}
        autoPlay
        muted
        playsInline
        className="w-full h-full object-cover relative z-20"
      />
      <audio
        title="Beep Sound"
        ref={audioRef}
        src={`/sounds/beep.mp3`}
      />
      <div
        className="absolute w-[70%] h-1 bg-white bg-opacity-70 z-40 rounded-full"
      />
    </>
  );
}
