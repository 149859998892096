import { create } from "zustand";
import { MotoristaFormData } from "@/types/motorista";

interface MotoristaState {
  motorista: MotoristaFormData | null;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  addMotorista: (motorista: MotoristaFormData) => void;
  setMotorista: (motorista: MotoristaFormData | null) => void;
}

export const useMotoristaStore = create<MotoristaState>((set) => ({
  motorista: null,
  isLoading: false,
  setIsLoading: (loading: boolean) => set({ isLoading: loading }),
  addMotorista: (motorista: MotoristaFormData) =>
    set(() => ({ motorista, isLoading: false })),
  setMotorista: (motorista: MotoristaFormData | null) =>
    set(() => ({ motorista, isLoading: false })),
}));
