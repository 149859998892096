import { ArrowLeft, X } from "lucide-react";
import { useCamera } from "@/hooks/use-camera";
import { useNavigate } from "react-router-dom";


export function CameraControlsTop() {

  const navigate = useNavigate();

  const { photoData, resetCamera } = useCamera();

  return (
    <div
      className="absolute flex top-4 text-sm w-full px-4 py-8 z-40 font-[family-name:var(--font-geist-sans)]"
    >
      {!photoData ? (
        <button
          className="absolute top-4 left-4 bg-white p-2 rounded-full shadow-md z-40"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="text-black" />
        </button>
      ) : (
        <button
          className="absolute top-4 left-4 bg-white p-2 rounded-full shadow-md z-40"
          onClick={() => resetCamera()}
        >
          <X className="text-black" />
        </button>
      )}
    </div>
  );
}