import { useCallback, useRef, useState } from "react";
import { scanImageData, ZBarSymbol } from "@undecaf/zbar-wasm";
import { useScannerStore } from "@/store/scanner-store";
import { convertToGrayscale, getMediaStream } from "@/lib/camera";
import { toast } from "./use-toast";
import { getDataChave } from "@/lib/utils";
import { useNavigate, useParams } from "react-router-dom";

export const useScanner = () => {
  const { databaseName } = useParams<{ databaseName: string }>();
  const navigate = useNavigate();

  const streamRef = useRef<MediaStream | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const requestRef = useRef<number | null>(null);

  const [error] = useState<string | null>(null);

  const {
    isScanning,
    videoRef,
    setIsScanning,
    setChave,
    setDataChave,
    setNumeroDocumento,
    setVideoRef,
  } = useScannerStore();

  const startScanner = useCallback(async () => {
    setIsScanning(true);

    try {
      const stream = await getMediaStream();

      if (!stream) {
        throw new Error("Could not access any camera");
      }

      streamRef.current = stream;

      const currentVideoRef = useScannerStore.getState().videoRef;
      if (!currentVideoRef?.current) {
        throw new Error("Video reference not found");
      }

      currentVideoRef.current.srcObject = stream;

      currentVideoRef.current!.onplay = async () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d", { willReadFrequently: true });
        const width = currentVideoRef.current?.videoWidth || 1280;
        const height = currentVideoRef.current?.videoHeight || 720;
        canvas.width = width;
        canvas.height = height;

        const scanFrame = async () => {
          // alert('entrou scan');
          // alert(currentVideoRef.current);
          // alert(isScanning);
          if (!currentVideoRef.current) return;

          // alert('entrou scan2');

          context?.drawImage(currentVideoRef.current, 0, 0, width, height);

          const imageData = context!.getImageData(0, 0, width, height);
          const grayscaleImageData = convertToGrayscale(imageData);

          let results: ZBarSymbol[] = [];

          if (grayscaleImageData) {
            results = await scanImageData(grayscaleImageData);
          }

          // alert(JSON.stringify(results));

          // toast({
          //   description: JSON.stringify(results) + ' ' + width + ' ' + height,
          //   variant: "default",
          // });

          if (results && results.length > 0) {
            if (
              results[0]?.typeName.replace("ZBAR_", "") !== "CODE128" ||
              results[0]?.decode().length !== 44
            ) {
              requestRef.current = requestAnimationFrame(scanFrame);
              return;
            }

            await audioRef?.current?.play();

            if ("vibrate" in navigator) {
              window?.navigator?.vibrate(300);
            }

            setTimeout(() => {
              const dataChave = getDataChave(results[0]?.decode());
              setChave(results[0]?.decode() || '');
              setDataChave(dataChave);
              setNumeroDocumento(dataChave?.numero || null);

              navigate(`/${databaseName}/foto`);
            }, 310);
          } else {
            requestRef.current = requestAnimationFrame(scanFrame);
          }
        };
        requestRef.current = requestAnimationFrame(scanFrame);
      };
    } catch (err: any) {
      toast({
        title: "Camera Error",
        description:
          err.message || "Unable to access camera. Please check permissions.",
        variant: "destructive",
      });
    }
  }, [isScanning, setIsScanning]);

  const stopScanner = useCallback(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = null;
    }

    const videoRef = useScannerStore.getState().videoRef;
    if (videoRef && videoRef.current) {
      videoRef.current.srcObject = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    setIsScanning(false);
  }, []);

  return {
    videoRef,
    audioRef,
    isScanning,
    error,
    startScanner,
    stopScanner,
    setVideoRef,
  };
};
