import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { initDB } from '@/hooks/use-indexed';

interface IndexedDBProviderProps {
  children: ReactNode;
}

const IndexedDBContext = createContext<{ isReady: boolean }>({ isReady: false });

export const useIndexedDBContext = () => useContext(IndexedDBContext);

export function IndexedDBProvider({ children }: IndexedDBProviderProps) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initializeDB = async () => {
      try {
        await initDB({
          name: "zorte-entrega",
          version: 1,
          objectStoresMeta: [
            {
              store: "entregas",
              storeConfig: { keyPath: "id", autoIncrement: true },
              storeSchema: [
                { name: "numero_documento", keypath: "numero_documento", options: { unique: false } },
                { name: "ocorrencia_id", keypath: "ocorrencia_id", options: { unique: false } },
                { name: "error", keypath: "error", options: { unique: false } },
                { name: "status", keypath: "status", options: { unique: false } },
                { name: "dt_ocorrencia", keypath: "dt_ocorrencia", options: { unique: false } },
                { name: "hora_ocorrencia", keypath: "hora_ocorrencia", options: { unique: false } },
                { name: "data_hora", keypath: ["dt_ocorrencia", "hora_ocorrencia"], options: { unique: false } }
              ],
            },
            {
              store: "motoristas",
              storeConfig: { keyPath: "id", autoIncrement: true },
              storeSchema: [
                { name: "placa", keypath: "placa", options: { unique: false } },
              ],
            },
          ],
        });
        setIsReady(true);
      } catch (error) {
        console.error('Failed to initialize IndexedDB:', error);
      }
    };

    initializeDB();
  }, []);

  if (!isReady) {
    return false;
  }

  return (
    <IndexedDBContext.Provider value={{ isReady }}>
      {children}
    </IndexedDBContext.Provider>
  );
}