import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const isPhone = () =>
  /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function clearNumber(numero: string | number | null): string {
  if (!numero) return "";

  let numeroLimpo = String(numero).replace(/[^0-9]/g, "");

  numeroLimpo = numeroLimpo.replace(/^0+/, "");

  return numeroLimpo === "" ? "" : numeroLimpo;
}

export interface DataChave {
  codigo_uf: string;
  ano_mes: string;
  cnpj_emitente: string;
  modelo: string;
  serie: string;
  numero: string;
  tipo_emissao: string;
  codigo: string;
  dv: string;
}

export function getDataChave(chave: string): DataChave {
  return {
    codigo_uf: chave.substring(0, 2),
    ano_mes: chave.substring(2, 6),
    cnpj_emitente: chave.substring(6, 20),
    modelo: chave.substring(20, 22),
    serie: chave.substring(22, 25),
    numero: String(parseInt(chave.substring(25, 34), 10)),
    tipo_emissao: chave.substring(34, 35),
    codigo: chave.substring(35, 43),
    dv: chave.substring(43, 44),
  };
}

export function arrayBufferToBase64(buffer: ArrayBuffer, type: string) {
  return new Promise((resolve) => {
    const blob = new Blob([buffer], { type });
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function getPayloadSizeMB(payload: any): number {
  /**
   * Calcula o tamanho de um payload em megabytes (MB)
   * @param payload - Pode ser string, object, array, etc.
   * @returns Tamanho em MB com 2 casas decimais
   */

  // Função auxiliar para calcular tamanho em bytes
  function getByteSize(data: any): number {
    if (data === null || data === undefined) return 0;

    // Se for string
    if (typeof data === "string") {
      return new Blob([data]).size;
    }

    // Se for objeto ou array
    if (typeof data === "object") {
      return new Blob([JSON.stringify(data)]).size;
    }

    // Para outros tipos (number, boolean)
    return new Blob([String(data)]).size;
  }

  // Calcula tamanho em bytes
  const sizeInBytes: number = getByteSize(payload);

  // Converte para MB (1 MB = 1024 * 1024 bytes)
  const sizeInMB: number = sizeInBytes / (1024 * 1024);

  // Retorna com 2 casas decimais
  return Number(sizeInMB.toFixed(2));
}
