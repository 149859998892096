import React from 'react';
import { Outlet } from 'react-router-dom';
import { BottomNavigation } from '../bottom-navigation/BottomNavigation';

const BottomNavLayout: React.FC = () => {

  return (
    <>
      <Outlet />
      <BottomNavigation />
    </>
  );
};

export default BottomNavLayout;